import React from "react";
import Helmet from "react-helmet";

export default function HomepageLinkingData() {
  const ld = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Feelmore Labs",
    url: "https://www.feelcove.com/",
    logo: "https://www.feelcove.com/img/logo-dark.svg",
    sameAs: [
      "https://www.instagram.com/feel_cove/",
      "https://www.facebook.com/covedevice",
      "https://twitter.com/feel_cove",
      "https://www.youtube.com/channel/UCNJ6l2S3l8tgYfALvWhIlSA"
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(ld)}</script>
    </Helmet>
  );
}
