// extracted by mini-css-extract-plugin
export var aaron = "styles-module--aaron--DfAaD";
export var accentOrb = "styles-module--accentOrb--FLWQN";
export var body = "styles-module--body--pZwbX";
export var closeModal = "styles-module--closeModal--kAnBJ";
export var closeModalBtn = "styles-module--closeModalBtn--S+jYh";
export var column = "styles-module--column--42NMN";
export var container = "styles-module--container--lB-OZ";
export var content = "styles-module--content--+jgW8";
export var ctaContainer = "styles-module--ctaContainer--g8TFP";
export var fadeIn = "styles-module--fadeIn--wM0Vu";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--peIuF";
export var flexContainer = "styles-module--flexContainer--qAifF";
export var headline = "styles-module--headline--rXr0L";
export var imageContainer = "styles-module--imageContainer--YW-FH";
export var modalContent = "styles-module--modalContent--iuU8l";
export var orb = "styles-module--orb--++UEP";
export var playCTA = "styles-module--playCTA--l9gSu";
export var scaleUp = "styles-module--scaleUp--uT24O";
export var singleAccentOrb = "styles-module--singleAccentOrb--2qC6l";
export var slideIn = "styles-module--slideIn--xpGzv";
export var wrapper = "styles-module--wrapper--Or50l";