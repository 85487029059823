// extracted by mini-css-extract-plugin
export var bgImage = "styles-module--bgImage--+zbEn";
export var cardContainer = "styles-module--cardContainer--KfEVs";
export var citation = "styles-module--citation--2nGpc";
export var container = "styles-module--container--91z9V";
export var desktopHeadline = "styles-module--desktopHeadline--47z7S";
export var fadeIn = "styles-module--fadeIn--58Mcx";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--ljpLx";
export var imagePreloader = "styles-module--imagePreloader--jj15+";
export var infoContainer = "styles-module--infoContainer--JkgLa";
export var mobileHeadline = "styles-module--mobileHeadline--SQmpD";
export var parentContainer = "styles-module--parentContainer--Imf99";
export var review = "styles-module--review--XD-at";
export var scaleUp = "styles-module--scaleUp--cc3Mj";
export var slideIn = "styles-module--slideIn--UbVuM";