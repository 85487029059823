import React from "react";
import SiteGrid from "../../components/SiteGrid";
import SiteGutter from "../../components/SiteGutter";
import * as styles from "./styles.module.scss";
import cx from "classname";
import accentOrbImg from "./Accent Orb.svg";
import singleAccentOrbImg from "./Single Accent Orb.svg";
import Body from "../../components/Body";
import Aaron_profile from "./Aaron_profile.png";
import Link from "../../components/Link";
import WaypointTag from "../../components/WaypointTag";

export default function Hero({ data }) {
  return (
    <div className={styles.container}>
      <img
        src={accentOrbImg}
        loading="lazy"
        alt=""
        className={styles.accentOrb}
      />
      <SiteGutter light>
        <SiteGrid>
          <div className={styles.flexContainer}>
            <div className={cx(styles.content, styles.column)}>
              <div className={styles.wrapper}>
                <div>
                  <Body
                    richText={data.headlineMulti}
                    className={styles.headline}
                  />

                  {data.body && (
                    <Body className={styles.body} richText={data.body} />
                  )}
                  <WaypointTag value="Hero" />
                  <div className={styles.ctaContainer}>
                    <Link
                      url={data.cta.url}
                      className={cx(styles.playCTA, "button yellow")}
                      label={data.cta.label}
                    />
                  </div>
                </div>
              </div>
            </div>
            {data.image && (
              <div className={cx(styles.imageContainer, styles.column)}>
                <div className={styles.aaron}>
                  <div className={styles.orb} />
                  <img src={Aaron_profile} alt={"Wearing cove"} />
                </div>
              </div>
            )}
          </div>
        </SiteGrid>
      </SiteGutter>
      <img
        src={singleAccentOrbImg}
        loading="lazy"
        alt=""
        className={styles.singleAccentOrb}
      />
    </div>
  );
}
