// extracted by mini-css-extract-plugin
export var columns = "styles-module--columns--Ib+P2";
export var container = "styles-module--container--eDenq";
export var content = "styles-module--content--efsc1";
export var cta = "styles-module--cta--6zFdd";
export var description = "styles-module--description--b5zQH";
export var desktopImage = "styles-module--desktopImage--5V4P6";
export var fadeIn = "styles-module--fadeIn--pku6Z";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--LKo-8";
export var headline = "styles-module--headline--d7sEE";
export var mobileImage = "styles-module--mobileImage--6g6yo";
export var right = "styles-module--right--qtXst";
export var scaleUp = "styles-module--scaleUp--bYNzh";
export var slideIn = "styles-module--slideIn--ZICSx";