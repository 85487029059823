import React from "react";
import cx from "classname";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import Headline from "../../components/Headline";
import Body from "../../components/Body";
import * as styles from "./styles.module.scss";

import Stress from "./assets/Stress.svg";
import Sleep from "./assets/Sleep.svg";
import Cognition from "./assets/Cognition.svg";

import StatsContainer from "./StatsContainer";
import Link from "../../components/Link";

function ByTheNumbers({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter>
        <SiteGrid>
          <div className={styles.header}>
            <div>
              <Headline className={styles.headline} type="s32">
                {data.headline}
              </Headline>
              <Body richText={data.body} className={styles.body} />
            </div>
          </div>
          <div className={styles.statsContainer}>
            <StatsContainer data={data.StressBenefits} image={Stress} />
            <StatsContainer
              data={data.SleepBenefits}
              image={Sleep}
              omitPercentageOnIndex={1}
            />
            <StatsContainer data={data.CognitionBenefits} image={Cognition} />
          </div>
          <Link url={data.cta.url} className={cx("button blue", styles.button)}>
            {data.cta.label}
          </Link>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}

export default ByTheNumbers;
