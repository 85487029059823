/*import React, { useEffect } from "react";
import BasePage from "../components/BasePage";
import Hero from "./Hero";
import HeroKevin from "./HeroKevin";
import KevinsExperience from "./KevinsExperience";
import AboutKevinLove from "./AboutKevinLove";
import Scroller from "./Scroller";
import Testimonials from "./Testimonials";
import PressBanner from "./PressBanner";
import ByTheNumbers from "./ByTheNumbers";
import Trial from "./Trial";
import App from "./App";
import useAnalytics, { pageView } from "../effects/useAnalytics";
import JsonLinkingData from "./JsonLinkingData";
import { Helmet } from "react-helmet";

const sliceMap = {
  Hero,
  HeroKevin,
  PressBanner,
  KevinsExperience,
  AboutKevinLove,
  Scroller,
  Testimonials,
  ByTheNumbers,
  App,
  Trial
};

export default function Home({ pageContext, location }) {
  const analytics = useAnalytics();
  useEffect(() => {
    pageView("Home Viewed");
    analytics.track("Home Viewed", {
      referrer: document.referrer
    });
  }, [analytics]);

  return (
    <>
      <BasePage pageContext={pageContext} location={location}>
        <Helmet>
          <script defer src="https://cdn.attn.tv/feelcove/dtag.js"></script>
        </Helmet>
        <JsonLinkingData />
        {Object.keys(pageContext.slices).map((sliceName) => {
          const sliceData = pageContext.slices[sliceName];
          const SliceComponent = sliceMap[sliceName];

          if (SliceComponent) {
            return (
              <>
                <SliceComponent key={sliceName} data={sliceData} />
              </>
            );
          } else {
            return null;
          }
        })}
      </BasePage>
    </>
  );
}*/
import React from "react";
import BasePage from "../components/BasePage";
import Hero from "./Hero";
import HeroKevin from "./HeroKevin";
import KevinsExperience from "./KevinsExperience";
import AboutKevinLove from "./AboutKevinLove";
import Scroller from "./Scroller";
import Testimonials from "./Testimonials";
import PressBanner from "./PressBanner";
import ByTheNumbers from "./ByTheNumbers";
import Trial from "./Trial";
import JsonLinkingData from "./JsonLinkingData";

const sliceMap = {
  Hero,
  HeroKevin,
  PressBanner,
  KevinsExperience,
  AboutKevinLove,
  Scroller,
  Testimonials,
  ByTheNumbers,
  Trial
};

export default function Home({ pageContext, location }) {
  return (
    <BasePage pageContext={pageContext} location={location}>
      <JsonLinkingData />
      {Object.keys(pageContext.slices).map((sliceName) => {
        // Add conditional checks
        if (pageContext && pageContext.slices) {
          const sliceData = pageContext.slices[sliceName];
          const SliceComponent = sliceMap[sliceName];
          console.log('slice', sliceName);

          if (SliceComponent) {
            return (
              <SliceComponent key={sliceName} data={sliceData} />
            );
          } else {
            return null;
          }
        } else {
          // Handle the case where pageContext or pageContext.slices is missing or undefined
          return null;
        }
      })}
    </BasePage>
  );
}
