import React, { useEffect, createRef } from "react";
import Headline from "../../components/Headline";
import SiteGrid from "../../components/SiteGrid";
import SiteGutter from "../../components/SiteGutter";
import Body from "../../components/Body";
import * as styles from "./styles.module.scss";
import OnViewportAppendClassToChild from "../../components/OnViewportAppendClassToChild";
import WaypointTag from "../../components/WaypointTag";
export default function ScrollerStatic({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter light>
        <SiteGrid>
          <div className={styles.grid}>
            <div className={styles.left}>
              {Object.values(data.Sections).map((section, i) => (
                <OnViewportAppendClassToChild key={i} className={styles.show}>
                  <div className={styles.section} data-section={i}>
                    <div className={styles.sectionWrapper}>
                      <div className={styles.sectionNumber}>
                        <span>{i + 1}</span>
                      </div>
                      <div className={styles.sectionContent}>
                        <Headline type="s24" className={styles.headline}>
                          {section.headline}
                        </Headline>
                        <Body richText={section.body} className={styles.body} />
                      </div>
                    </div>
                  </div>
                </OnViewportAppendClassToChild>
              ))}
            </div>
            <div className={styles.right}>
              <WaypointTag value="Scroller" />
              <VimeoWithPreview
                title="Cove"
                vimeoUrl="https://player.vimeo.com/video/589353807?background=1&loop=1&autoplay=0"
              />
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}

function VimeoWithPreview({ title, vimeoUrl, imageUrl }) {
  const video = createRef();
  const image = createRef();

  useEffect(() => {
    let players = [];

    const hidePlaceholder = () => {
      image.current.style.opacity = "0";
    };

    import("@vimeo/player").then(({ default: Player }) => {
      const player = new Player(video.current);
      player.on("play", hidePlaceholder);
      player.on("loaded", () => {
        player.play();
      });

      players.push(player);
    });

    return () => {
      players.forEach((player) => player.off("play", hidePlaceholder));
    };
  }, [video, image]);

  return (
    <div className={styles.vimeoWithPreview}>
      <iframe
        ref={video}
        title={title}
        src={vimeoUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen=""
      ></iframe>
    </div>
  );
}
