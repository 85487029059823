import { useEffect, useState } from "react";

export default function useIsDesktop() {
  const [isDesktop, setDesktop] = useState(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    updateMedia();
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return isDesktop;
}
