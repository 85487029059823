import React from "react";
import * as styles from "./styles.module.scss";
import SiteGutter from "../../components/SiteGutter";
import Headline from "../../components/Headline";
import SiteGrid from "../../components/SiteGrid";
import cx from "classname";
import mobileImage from "./assets/bgMobile.jpg";

export default function HeroKevin({ data }) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.videoBg}></div>
        <SiteGutter>
          <SiteGrid>
            <div className={styles.wrapper}>
              <div className={styles.content}>
                <div>
                  <Headline type="s48" className={styles.headline}>
                    {data.headline}
                  </Headline>
                  <div className={styles.subhead}>{data.subhead}</div>

                  <div className={styles.citation}>{data.citation}</div>

                  <img
                    src={mobileImage}
                    alt="Kevin Love wearing Cove"
                    className={cx("mobileOnly", styles.kevinMobile)}
                    loading="eager"
                  />
                </div>
              </div>
            </div>
          </SiteGrid>
        </SiteGutter>
      </div>
    </>
  );
}
