import React, { useEffect, createRef } from "react";
import { overlay, modal } from "./styles.module.scss";
import { useEscape } from "../../effects/useKeyPress";

export default function Modal({ children, onClose }) {
  const overlayRef = createRef();

  useEscape(onClose);

  useEffect(() => {
    const btn = overlayRef.current.querySelector("button");
    if (btn) {
      btn.focus();
    }
  }, [overlayRef]);

  function preventBubble(e) {
    e.stopPropagation();
  }

  return (
    <div
      className={overlay}
      onClick={onClose}
      ref={overlayRef}
      role="presentation"
    >
      <div className={modal} onClick={preventBubble} role="presentation">
        {children}
      </div>
    </div>
  );
}
