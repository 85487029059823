import React from "react";
import * as styles from "./styles.module.scss";
import accentOrbImg from "./assets/Accent Orb.svg";
import accentOrb2Img from "./assets/Accent Orb 2.svg";
import mobileOrbImg from "./assets/Mobile Orb.svg";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import useIsDesktop from "../../effects/useIsDesktop";
import Slider from "../../components/Slider";
import FeaturedTestimonials, { TestimonialCard } from "./FeaturedTestimonials";
import WaypointTag from "../../components/WaypointTag";
import Headline from "../../components/Headline";
import Body from "../../components/Body";

const defaultOptions = {
  arrows: false,
  autoplay: false,
  pagination: true,
  type: "slide",
  perPage: 1
};

export default function Testimonials({ data }) {
  const isDesktop = useIsDesktop();
  return (
    <>
      <WaypointTag value="Testimonials" />

      <div className={styles.container}>
        <img
          src={accentOrbImg}
          loading="lazy"
          alt=""
          className={styles.accentOrb}
        />
        <img
          src={accentOrb2Img}
          loading="lazy"
          alt=""
          className={styles.accentOrb2}
        />
        <img
          src={mobileOrbImg}
          loading="lazy"
          alt=""
          className={styles.mobileOrb}
        />
        <SiteGutter light>
          <SiteGrid>
            <div className={styles.header}>
              <Headline type="s32" className={styles.headline}>
                {data.headline}
              </Headline>
              <Body richText={data.body} />
            </div>
          </SiteGrid>
        </SiteGutter>
        {isDesktop ? (
          <SiteGutter light>
            <SiteGrid>
              <FeaturedTestimonials data={data} />
            </SiteGrid>
          </SiteGutter>
        ) : (
          <Slider options={defaultOptions} className={styles.sliderContainer}>
            {Object.values(data.TestimonialsList).map((testimonial, i) => (
              <TestimonialCard key={i} testimonial={testimonial} isMobile />
            ))}
          </Slider>
        )}
      </div>
    </>
  );
}
