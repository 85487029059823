import React from "react";
import * as styles from "./styles.module.scss";
import Body from "../../components/Body";
import Headline from "../../components/Headline";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import WaypointTag from "../../components/WaypointTag";
export default function Trial({ data }) {
  return (
    <div className={styles.container}>
      <SiteGrid>
        <SiteGutter>
          <div className={styles.columns}>
            <div className={styles.content}>
              <div>
                <Headline type="s32" className={styles.headline}>
                  {data.headline}
                </Headline>
                <Body richText={data.body} className={styles.description} />
                <WaypointTag value="Trial" />
              </div>
            </div>
            <div className={styles.right}>
              <img
                loading="lazy"
                src={data.desktopImage.image.file.url}
                alt=""
                className={styles.desktopImage}
              />
              <img
                loading="lazy"
                src={data.mobileImage.image.file.url}
                alt=""
                className={styles.mobileImage}
              />
            </div>
          </div>
        </SiteGutter>
      </SiteGrid>
    </div>
  );
}
