// extracted by mini-css-extract-plugin
export var accentOrb = "styles-module--accentOrb--ED2Y0";
export var accentOrb2 = "styles-module--accentOrb2--UvHUt";
export var container = "styles-module--container--nZKVH";
export var fadeIn = "styles-module--fadeIn--8zB9L";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--eSXjm";
export var header = "styles-module--header--z6D+c";
export var headline = "styles-module--headline--qu6wP";
export var mobileOrb = "styles-module--mobileOrb--O3XPJ";
export var scaleUp = "styles-module--scaleUp--Wps58";
export var slideIn = "styles-module--slideIn--F8Q5l";
export var sliderContainer = "styles-module--sliderContainer--05Fen";