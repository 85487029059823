// extracted by mini-css-extract-plugin
export var body = "styles-module--body--oiWjU";
export var container = "styles-module--container--CJUYF";
export var contentContainer = "styles-module--contentContainer--y6XW+";
export var cta = "styles-module--cta--td1m6";
export var fadeIn = "styles-module--fadeIn--XYo2J";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--8BAzr";
export var headline = "styles-module--headline--eTOnq";
export var kevinLoveMobile = "styles-module--kevinLoveMobile--HEYrN";
export var left = "styles-module--left--2L1e9";
export var leftMobileOrb = "styles-module--leftMobileOrb--P68wb";
export var portrait = "styles-module--portrait--Zrm2d";
export var right = "styles-module--right--FEAoT";
export var rightMobileOrb = "styles-module--rightMobileOrb--d2u9X";
export var rightOrb = "styles-module--rightOrb--y7qh5";
export var scaleUp = "styles-module--scaleUp--xai57";
export var slideIn = "styles-module--slideIn--zaSVe";
export var topOrb = "styles-module--topOrb--QD4WK";