import React from "react";
import * as styles from "./styles.module.scss";
import Body from "../../../components/Body";
import cx from "classname";
import Headline from "../../../components/Headline";
import StarRating from "../../../components/StarRatings";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function FeaturedTestimonials({ data }) {
  return (
    <div className={styles.container}>
      {Object.values(data.TestimonialsList).map((testimonial, i) => (
        <TestimonialCard key={i} testimonial={testimonial} />
      ))}
    </div>
  );
}

export function TestimonialCard({ testimonial, className, isMobile }) {
  const { desktopImage, mobileImage, rating, headline, body, citation } =
    testimonial;
  const bgImage = isMobile ? mobileImage : desktopImage;
  const ratingNo = Number(rating);

  return (
    <div className={styles.parentContainer}>
      <Headline className={styles.desktopHeadline} type="s24">
        {headline}
      </Headline>
      <div
        className={cx(styles.cardContainer, className)}
        style={{
          height: `${bgImage.image.file.details.image.height}px`,
          maxWidth: `${bgImage.image.file.details.image.width}px`
        }}
      >
        <GatsbyImage
          image={getImage(bgImage.image.gatsbyImageData)}
          className={styles.bgImage}
          alt=""
        />

        <Headline className={styles.mobileHeadline} type="s24">
          {headline}
        </Headline>
        <div className={styles.infoContainer}>
          <StarRating rating={ratingNo} />
          <Body richText={body} className={styles.review} />
          <span className={styles.citation}>{citation}</span>
        </div>
      </div>
    </div>
  );
}
