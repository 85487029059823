// extracted by mini-css-extract-plugin
export var body = "styles-module--body--fp5Bm";
export var button = "styles-module--button--0A-16";
export var container = "styles-module--container--3bQie";
export var fadeIn = "styles-module--fadeIn--VK-OM";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--IP-Nk";
export var header = "styles-module--header--DXA9s";
export var headline = "styles-module--headline--qLc2a";
export var scaleUp = "styles-module--scaleUp--u3d-Y";
export var slideIn = "styles-module--slideIn--AcyEG";
export var statsContainer = "styles-module--statsContainer--oopUw";