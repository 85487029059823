import React from "react";
import Headline from "../../../components/Headline";
import * as styles from "./styles.module.scss";

function StatsContainer({ data, image }) {
  const { headline, cta } = data;

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <img src={image} className={styles.icon} alt="" />
      </div>

      <Headline className={styles.headline} type="s24">
        {headline}
      </Headline>

      <a href={cta.url} className={styles.cta}>
        {cta.label}
      </a>
    </div>
  );
}

export default StatsContainer;
