import React, { useState } from "react";
import Body from "../../components/Body";
import Modal from "../../components/Modal";
import { Link } from "gatsby";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import * as styles from "./styles.module.scss";
import cx from "classname";

import coveInUse from "./assets/coveInUse.jpg";
import bottomRightOrb from "./assets/bottomRightOrb.svg";
import coveInUseMobile from "./assets/coveInUseMobile.jpg";
import playIcon from "./assets/ico_play.svg";

function Video({ onClose }) {
  return (
    <>
      <button onClick={onClose} className={styles.closeModal}>
        Close
      </button>
      <div className={styles.videoContainer}>
        <iframe
          src="https://player.vimeo.com/video/692326647?h=2fb176496d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullscreen
          title="220309_COVE_30_FINAL_WEB"
        ></iframe>
      </div>
    </>
  );
}

export default function AboutKevinLove({ data }) {
  const [isVisible, setVisible] = useState(false);
  const handleCtaClick = () => setVisible(true);
  const handleClose = () => setVisible(false);
  return (
    <>
      {isVisible && (
        <Modal onClose={handleClose}>
          <Video onClose={handleClose} />
        </Modal>
      )}
      <div className={styles.container}>
        <SiteGutter light>
          <SiteGrid>
            <div className={styles.contentContainer}>
              <div>
                <img
                  src={coveInUse}
                  className={styles.coveInUse}
                  alt="Cove in use"
                  loading="lazy"
                />
              </div>
              <div className={styles.right}>
                <Body
                  tagType="h2"
                  className={styles.headline}
                  richText={data.body}
                />
                <Body className={styles.quoteCitation} richText={data.body1} />
                <div className={styles.ctaContainer}>
                  <Body className={styles.ctaEyebrow}>{data.ctaEyebrow}</Body>
                  <Link
                    to={data.cta.url}
                    className={cx("button yellow", styles.cta)}
                    onClick={handleCtaClick}
                  >
                    {data.cta.label}
                    <img
                      src={playIcon}
                      className={styles.playIcon}
                      alt=""
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </SiteGrid>
        </SiteGutter>
        <img
          src={bottomRightOrb}
          className={styles.bottomRightOrb}
          alt=""
          loading="lazy"
        />
        <img
          src={coveInUseMobile}
          className={styles.coveInUseMobile}
          alt="Cove in use"
          loading="lazy"
          width="100%"
          height="100%"
        />
      </div>
    </>
  );
}
