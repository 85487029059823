// extracted by mini-css-extract-plugin
export var body = "styles-module--body--0AObJ";
export var container = "styles-module--container--LbclH";
export var cta = "styles-module--cta--nu4wk";
export var fadeIn = "styles-module--fadeIn--5h4jQ";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--ul9cv";
export var grid = "styles-module--grid--7GZye";
export var headline = "styles-module--headline--FpaHb";
export var left = "styles-module--left--GRMqC";
export var right = "styles-module--right--zozPe";
export var scaleUp = "styles-module--scaleUp--GUvwh";
export var section = "styles-module--section--tSJbN";
export var sectionContent = "styles-module--sectionContent--3v2qw";
export var sectionNumber = "styles-module--sectionNumber--GqJQu";
export var sectionWrapper = "styles-module--sectionWrapper--Nj00r";
export var show = "styles-module--show--yfUZ0";
export var slideIn = "styles-module--slideIn--iGGhS";
export var vimeoWithPreview = "styles-module--vimeoWithPreview--3VXbo";