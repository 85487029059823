import React, { useLayoutEffect, createRef } from "react";

export default function AppendClasseOnViewportReveal({
  children,
  rootMargin,
  threshold,
  className
}) {
  const container = createRef();

  useLayoutEffect(() => {
    const options = {
      rootMargin: rootMargin || "0px",
      threshold: threshold || 0.1
    };

    function appendClass() {
      container.current.children[0].classList.add(className);
    }

    try {
      const target = container.current;
      const observer = new IntersectionObserver((intersections) => {
        if (intersections[0].isIntersecting) {
          appendClass();
          observer.unobserve(target);
        }
      }, options);
      observer.observe(target);
      return () => {
        observer.unobserve(target);
      };
    } catch (e) {
      appendClass();
    }
  }, [rootMargin, className, threshold, container]);

  return <div ref={container}>{children}</div>;
}
