import React from "react";
import Body from "../../components/Body";
import Headline from "../../components/Headline";
import SiteGutter from "../../components/SiteGutter";
import SiteGrid from "../../components/SiteGrid";
import * as styles from "./styles.module.scss";
import kevinLovePortrait from "./assets/kevinLovePortrait.png";
import topOrb from "./assets/topOrb.svg";
import kevinLoveMobile from "./assets/kevinLoveMobile.png";
import leftMobileOrb from "./assets/leftMobileOrb.svg";
import { Link } from "gatsby";

export default function AboutKevinLove({ data }) {
  return (
    <div className={styles.container}>
      <SiteGutter light>
        <SiteGrid>
          <img className={styles.topOrb} src={topOrb} alt="" />
          <div className={styles.contentContainer}>
            <div className={styles.left}>
              <Headline type="s32" className={styles.headline}>
                {data.headline}
              </Headline>
              <Body className={styles.body} richText={data.body} />
              <Link to={data.cta.url} className={`${styles.cta} linkUnderline`}>
                {data.cta.label}
              </Link>
            </div>
            <div className={styles.right}>
              <img
                src={kevinLovePortrait}
                className={styles.portrait}
                alt="Kevin Love"
                loading="lazy"
              />
              <img
                className={styles.leftMobileOrb}
                src={leftMobileOrb}
                alt=""
                loading="lazy"
              />
              <img
                className={styles.kevinLoveMobile}
                src={kevinLoveMobile}
                alt="Kevin Love"
                loading="lazy"
              />
            </div>
          </div>
        </SiteGrid>
      </SiteGutter>
    </div>
  );
}
