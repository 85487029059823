import React, { useEffect, createRef } from "react";

function WaypointTag({ value }) {
  return (
    <WaypointCallback
      onVisible={() => {
        window.coveLastSeenWaypointTag = value;
      }}
    />
  );
}

function WaypointCallback({ offset, threshold, onVisible }) {
  const div = createRef();

  useEffect(() => {
    const supportsIntersectionObserver = "IntersectionObserver" in window;

    if (!supportsIntersectionObserver) {
      return;
    }

    const onIntersect = (entries) => {
      const isVisible = entries[0].isIntersecting;
      if (isVisible && onVisible) {
        onVisible();
      }
    };

    const options = {
      rootMargin: offset,
      threshold: threshold
    };

    const observer = new IntersectionObserver(onIntersect, options);

    observer.observe(div.current);

    return () => observer.disconnect();
  }, [offset, threshold, div, onVisible]);

  return <div ref={div}></div>;
}

WaypointCallback.defaultProps = {
  offset: "0px",
  threshold: 0.1,
  onVisible: () => {}
};

export default WaypointTag;
